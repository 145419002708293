import React from 'react'

const WelcomeMessage = ({ message }) => (
  <div className="WelcomeMessage rcw-message rcw-message-client">
    <div className="rcw-client">
      <div className="rcw-message-text">
        <p className="dib">
          {message}

          <a className="dib" href="http://help.pluginboutique.com/" target="_blank" rel="noopener noreferrer">
            click here
          </a>
        </p>
      </div>
    </div>
  </div>
)

export default WelcomeMessage
