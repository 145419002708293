import React, { useState, useEffect, useCallback } from 'react'
import { Widget, addResponseMessage, addUserMessage, renderCustomComponent, toggleMsgLoader } from 'react-chat-widget'
import 'react-chat-widget/lib/styles.css'
import DefaultMessage from './DefaultMessage'
import AnswerMessage from './AnswerMessage'
import WelcomeMessage from './WelcomeMessage'
import { performRequest } from './api'

const MAX_MESSAGES_AMOUNT = 50
const MAX_MESSAGE_LENGTH = 300
const ERROR_MESSAGE = 'Your message is too long. Please keep it under 300 characters.'

const GCPContainer = ({ chatConfig }) => {
  const [messagesHistory, setMessagesHistory] = useState(JSON.parse(sessionStorage.getItem('messagesHistory')) || [])

  useEffect(() => {
    renderWelcomeMessage()
    renderDefaultMessages()
    renderMessagesHistory()
  }, []) // Empty dependency array means this effect runs once, similar to componentDidMount

  const renderWelcomeMessage = () => {
    console.log(chatConfig, 'chat config')

    renderCustomComponent(WelcomeMessage, { message: chatConfig.welcome_message })
  }

  const renderDefaultMessages = () => {
    const defaultMessages = [
      chatConfig.default_message1,
      chatConfig.default_message2,
      chatConfig.default_message3,
    ]

    defaultMessages.forEach(message => {
      renderCustomComponent(DefaultMessage, {
        message,
        handleMessageClick: handleDefaultMessageClick,
      })
    })
  }

  const renderMessagesHistory = () => {
    messagesHistory.forEach(data => {
      if (data.type === 'answer') {
        renderCustomComponent(AnswerMessage, {
          products: data.products,
          answer: data.message,
          cannedStart: chatConfig.answer_reply_beginning,
          cannedEnd: chatConfig.answer_reply_end,
        })
      } else if (data.type === 'default') {
        addUserMessage(data.message)
      } else {
        addResponseMessage(data.message)
      }
    })
  }

  const handleDefaultMessageClick = useCallback((message) => {
    addUserMessage(message)
    handleNewUserMessage(message)
  }, [])

  const renderAnswerMessage = useCallback((responseJson) => {
    renderCustomComponent(AnswerMessage, {
      ...responseJson,
      cannedStart: chatConfig.answer_reply_beginning,
      cannedEnd: chatConfig.answer_reply_end,
    })
  }, [chatConfig])

  const updateMessagesHistory = useCallback((message, products, type) => {
    setMessagesHistory(prevState => {
      let newHistoryState = [...prevState, { message, products, type }]

      // Ensure the history does not exceed 50 messages
      if (newHistoryState.length > MAX_MESSAGES_AMOUNT) {
        newHistoryState = newHistoryState.slice(1)
      }

      sessionStorage.setItem('messagesHistory', JSON.stringify(newHistoryState))

      return newHistoryState
    })
  }, [])

  const handleNewUserMessage = useCallback((newMessage) => {
    updateMessagesHistory(newMessage, null, 'default')
    toggleMsgLoader()

    if (newMessage.length >= MAX_MESSAGE_LENGTH) {
      updateMessagesHistory(ERROR_MESSAGE, null, 'answerError')
      addResponseMessage(ERROR_MESSAGE)
      toggleMsgLoader()
    } else {
      performRequest(newMessage, renderAnswerMessage, updateMessagesHistory)
    }
  }, [updateMessagesHistory, renderAnswerMessage])

  return (
    <div className="GCPContainer">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="Plugin Boutique Recommender"
        subtitle=""
        showTimeStamp={true}
      />
    </div>
  )
}

export default GCPContainer
