import React from 'react'

const DefaultMessage = ({ message, handleMessageClick }) => (
  <div className="DefaultMessage rcw-message rcw-message-client" onClick={() => handleMessageClick(message)}>
    <div className="rcw-client">
      <div className="rcw-message-text">
        <p>
          {message}
        </p>
      </div>
    </div>
  </div>
)

export default DefaultMessage
