import React from 'react'

const AnswerMessage = ({ products, answer, cannedStart, cannedEnd }) => {
  const formatProductInfo = (product) => {
    const percentageDiscount = product.percentage_discount
    const formatted_discount = (percentageDiscount && `(-${percentageDiscount}%)`) || ''

    return `${product.name} by ${product.manufacturer_name}: ${product.sell_price}${formatted_discount}`
  }

  return(
    <div className="AnswerMessage rcw-message rcw-message-client">
      <div className="rcw-client">
        <div className="rcw-message-text">
          <p className="">
            {cannedStart}
          </p>
          { products.length > 0 && (
            <>
              { products.map(product => (
                <a
                  key={product.id}
                  className="db"
                  href={product.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatProductInfo(product)}
                </a>
              ))}
            </>
          )}
          <p className="">
            {`\n${answer}`}
          </p>
          <p className="">
            {cannedEnd}
          </p>
        </div>
      </div>
    </div>
  )
}

export default AnswerMessage
