import { toggleMsgLoader } from 'react-chat-widget'
import csrfToken from '../../src/csrf_token'

export const performRequest = async (message, renderAnswerMessage, updateMessagesHistory) => {
  try {
    const response = await fetch(`/api/gcp/search?query=${encodeURIComponent(message)}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })

    if (!response.ok) {
      throw new Error(response)
    }

    const responseJson = await response.json()

    renderAnswerMessage(responseJson)
    updateMessagesHistory(responseJson.answer, responseJson.products, 'answer')
  } catch (error) {
    console.error(error)
    const message = 'Sorry, we couldn\'t process your request. Please try again later or contact support if the issue persists.'

    addResponseMessage(message)
    updateMessagesHistory(message, null, 'default')
  } finally {
    toggleMsgLoader()
  }
}
