const PIB = {
  addToCart: (productId) => {
    const data = { id: productId }

    fetch('/orders/add_to_cart', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/javascript',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(data)

    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to add to cart');
      }
    }).then(data => {
      Mess.publish('refresh_cart', data)

    }).catch(error => {
      console.error('Error:', error);
    });
  },

  addToWishlist: (productId) => {
    const data = { id: productId }

    fetch('/wishlists/add_to_wishlist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/javascript',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(data)

    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to add to wishlist');
      }
    }).then(data => {
      Mess.publish('refresh_wishlist', data)

    }).catch(error => {
      console.error('Error:', error);
    });
  }
};
window.PIB = PIB;
